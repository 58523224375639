<template>
  <div>
    <sdPageHeader title="Leverancier">
    </sdPageHeader>
    <Main v-if="errorSupplier">
      <sdCards title="">
        <a-result status="warning" title="Er is een fout opgetreden bij het ophalen van de gegevens."
                  style="padding: 0">
          <template #extra>
            <Button class="ant-btn ant-btn-primary" type="primary" size="large" @click="$router.replace('/supplier')">
              Naar leveranciers
            </Button>
          </template>
        </a-result>
      </sdCards>
    </Main>
    <Main v-else>
      <a-row :gutter="15" type="flex">
        <a-col :sm="18" :xs="24" style="display: flex;flex-flow: column;">
          <Upload style="flex: 1 1 auto;">
            <div class="user-info-form">
              <BasicFormWrapper class="mb-25">
                <sdCards title="Algemeen">
                  <a-form :model="form" :rules="rules1" layout="vertical" @finish="UpdateSupplier1">
                    <a-row :gutter="30">
                      <a-col :sm="12" :xs="24" class="mb-25">
                        <a-form-item label="Naam" name="name">
                          <a-input :disabled="!$can('update', 'supplier')" v-model:value="form.name" placeholder=""/>
                        </a-form-item>
                      </a-col>
                      <a-col :sm="12" :xs="24" class="mb-25">
                        <a-form-item label="Display naam" name="displayName">
                          <a-input :disabled="!$can('update', 'supplier')" v-model:value="form.displayName" placeholder=""/>
                        </a-form-item>
                      </a-col>
                      <a-col :sm="12" :xs="24" class="mb-25">
                        <a-form-item label="Kenmerk" name="externalReference">
                          <a-input :disabled="!$can('update', 'supplier')" v-model:value="form.externalReference" placeholder=""/>
                        </a-form-item>
                      </a-col>
                      <a-col :sm="12" :xs="24" class="mb-25">
                        <a-form-item label="E-mailadres" name="email">
                          <a-input :disabled="!$can('update', 'supplier')" v-model:value="form.email" placeholder=""/>
                        </a-form-item>
                      </a-col>
                      <a-col :sm="12" :xs="24" class="mb-25">
                        <a-form-item label="Telefoonnummer" name="phoneNumber">
                          <a-input :disabled="!$can('update', 'supplier')" v-model:value="form.phoneNumber" placeholder=""/>
                        </a-form-item>
                      </a-col>
                      <a-col :sm="12" :xs="24" class="mb-25">
                        <a-form-item label="Website" name="website">
                          <a-input :disabled="!$can('update', 'supplier')" v-model:value="form.website" placeholder=""/>
                        </a-form-item>
                      </a-col>
                      <a-col :sm="12" :xs="24" class="mb-25">
                        <a-form-item label="KvK-nummer" name="cocNumber">
                          <a-input :disabled="!$can('update', 'supplier')" v-model:value="form.cocNumber" placeholder=""/>
                        </a-form-item>
                      </a-col>
                    </a-row>
                    <a-row>
                      <a-col :sm="12" :xs="24">
                        <div class="sDash_form-action">
                          <sdButton v-if="$can('update', 'supplier')" class="btn-signin" type="primary"> Opslaan</sdButton>
                        </div>
                      </a-col>
                    </a-row>
                  </a-form>
                </sdCards>
              </BasicFormWrapper>
            </div>
          </Upload>
        </a-col>
        <a-col :sm="6" :xs="24" style="display: flex;flex-flow: column;">
          <sdCards title="Informatie" style="flex: 1 1 auto;">
            <a-row :gutter="30">
              <a-col :xs="24" class="mb-25">
                <InfoWrapper>
                  <div class="info-title">ID</div>
                  <div class="info-text">{{ form.id }}</div>
                </InfoWrapper>

                <InfoWrapper>
                  <div class="info-title"> Datum aangemaakt</div>
                  <div class="info-text">{{ moment(form.createTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
                </InfoWrapper>
                <InfoWrapper>
                  <div class="info-title"> Datum geupdate</div>
                  <div class="info-text">{{ moment(form.modifiedTimestamp).format('DD-MM-YYYY HH:mm:ss') }}</div>
                </InfoWrapper>
              </a-col>
            </a-row>
          </sdCards>
        </a-col>
      </a-row>
      <div class="user-info-form">
        <a-skeleton v-if="loadingSupplier" active/>
        <BasicFormWrapper v-else class="mb-25">
          <sdCards title="Adres">
            <a-form :model="form" :rules="rules2" layout="vertical" @finish="UpdateSupplier2">
              <a-row :gutter="30">
                <a-col :sm="9" :xs="24" class="mb-25">
                  <a-form-item label="Postcode" name="postalCode">
                    <a-input :disabled="!$can('update', 'supplier')" v-model:value="form.postalCode" placeholder=""/>
                  </a-form-item>
                </a-col>
                <a-col :sm="6" :xs="24" class="mb-25">
                  <a-form-item label="Huisnummer" name="houseNumber">
                    <a-input :disabled="!$can('update', 'supplier')" v-model:value="form.houseNumber" placeholder=""/>
                  </a-form-item>
                </a-col>
                <a-col :sm="6" :xs="24" class="mb-25">
                  <a-form-item label="Huisnummertoevoeging" name="houseNumberAddition">
                    <a-input :disabled="!$can('update', 'supplier')" v-model:value="form.houseNumberAddition" placeholder=""/>
                  </a-form-item>
                </a-col>
                <a-col :sm="3" :xs="24" class="mb-25">
                  <a-form-item label="&nbsp;">
                    <a-button v-if="$can('update', 'supplier')" size="large" class="btn-signin" type="primary" html-type="submit"
                                            :loading="loading">
                      <sdFeatherIcons type="search" size="16" class="mr-1"/>
                      <span>Zoeken</span>
                    </a-button>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="30">
                <a-col :sm="10" :xs="24" class="mb-25">
                  <a-form-item label="Straat" name="street">
                    <a-input :disabled="!$can('update', 'supplier')" v-model:value="form.street" placeholder=""/>
                  </a-form-item>
                </a-col>
                <a-col :sm="7" :xs="24" class="mb-25">
                  <a-form-item label="Plaats" name="city">
                    <a-input :disabled="!$can('update', 'supplier')" v-model:value="form.city" placeholder=""/>
                  </a-form-item>
                </a-col>
                <a-col :sm="7" :xs="24" class="mb-25">
                  <a-form-item label="Land" name="country">
                    <a-select :disabled="!$can('update', 'supplier')" v-model:value="form.country" size="large" name="country"
                              class="sDash_fullwidth-select"
                              :allowClear="true">
                      <a-select-option v-for="(item,index) in countryOptions" :key="index" :value="item.value">
                        {{ item.key }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :sm="12" :xs="24">
                  <div class="sDash_form-action">
                    <sdButton v-if="$can('update', 'supplier')" class="btn-signin" type="primary"> Opslaan</sdButton>
                  </div>
                </a-col>
              </a-row>
            </a-form>
          </sdCards>
        </BasicFormWrapper>
      </div>
<!--      <div class="user-info-form">-->
<!--        <a-skeleton v-if="loadingSupplier" active/>-->
<!--        <BasicFormWrapper v-else class="mb-25">-->
<!--          <sdCards title="Kleuren">-->
<!--            <a-form :model="form" :rules="rules3" layout="vertical" @finish="UpdateSupplier3">-->
<!--              <a-row :gutter="30" class="mb-25">-->
<!--                <a-col :span="12">-->
<!--                  <a-form-item label="Primaire kleur" name="primaryColor">-->
<!--                    <a-input-->
<!--                        :disabled="!$can('update', 'supplier')"-->
<!--                        :style="{ height: '55px' }"-->
<!--                        name="color"-->
<!--                        type="color"-->
<!--                        v-model:value="form.primaryColor"-->
<!--                    />-->
<!--                  </a-form-item>-->
<!--                </a-col>-->
<!--                <a-col :span="12">-->
<!--                  <a-form-item label="Secundaire kleur" name="secondaryColor">-->
<!--                    <a-input-->
<!--                        :disabled="!$can('update', 'supplier')"-->
<!--                        :style="{ height: '55px' }"-->
<!--                        name="color"-->
<!--                        type="color"-->
<!--                        v-model:value="form.secondaryColor"-->
<!--                    />-->
<!--                  </a-form-item>-->
<!--                </a-col>-->
<!--              </a-row>-->
<!--              <a-row>-->
<!--                <a-col :sm="12" :xs="24">-->
<!--                  <div class="sDash_form-action">-->
<!--                    <sdButton v-if="$can('update', 'supplier')" class="btn-signin" type="primary"> Opslaan</sdButton>-->
<!--                  </div>-->
<!--                </a-col>-->
<!--              </a-row>-->
<!--            </a-form>-->
<!--          </sdCards>-->
<!--        </BasicFormWrapper>-->
<!--      </div>-->
      <sdCards>
        <a-row>
          <a-col :xs="24">
            <div class="sDash_form-action">
              <sdButton class="btn-signin" html-type="submit" type="light" @click="$router.replace('/supplier')">
                Terug
              </sdButton>
            </div>
          </a-col>
        </a-row>
      </sdCards>
    </Main>
  </div>
</template>

<script>
import {BasicFormWrapper, InfoWrapper, Main, Upload} from '../styled';
import {computed, defineComponent, onMounted, reactive} from 'vue';
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import moment from "moment";
import {countryOptions, tfaOptions} from "../../utility/enums";
import {getItem} from "../../utility/localStorageControl";
import {getApiURL} from "../../config/dataService/dataService";

export default defineComponent({
  name: 'SupplierEdit',
  components: {
    BasicFormWrapper,
    Main,
    Upload,
    InfoWrapper,
  },
  data() {
    return {
      fileUrl: getApiURL(),
    }
  },
  setup() {
    const {state, dispatch} = useStore();
    const {params} = useRoute();
    const form = reactive(computed(() => state.supplier.supplier));
    const errorSupplier = computed(() => state.supplier.errorSupplier);
    const loadingSupplier = computed(() => state.supplier.loadingSupplier);
    const rules1 = {
      name: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      displayName: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      cocNumber: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      email: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      phoneNumber: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
    };
    const rules2 = {
      city: [
        {
          required: true,
          message: 'Please enter user name',
        },
      ],
      country: [
        {
          required: true,
          message: 'Please enter user name',
        },
      ],
      houseNumber: [
        {
          required: true,
          message: 'Please enter user name',
        },
      ],
      postalCode: [
        {
          required: true,
          message: 'Please enter user name',
        },
      ],
      street: [
        {
          required: true,
          message: 'Please enter user name',
        },
      ],
    };
    const rules3 = {
      primaryColor: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
      secondaryColor: [
        {
          required: true,
          message: 'Dit veld is verplicht',
        },
      ],
    };

    const UpdateSupplier1 = () => {
      dispatch('patchSupplier', {
        cocNumber: form.value.cocNumber,
        displayName: form.value.displayName,
        email: form.value.email,
        externalReference: form.value.externalReference,
        id: params.id,
        name: form.value.name,
        phoneNumber: form.value.phoneNumber,
        website: form.value.website,

      });
    };

    const UpdateSupplier2 = () => {
      dispatch('patchSupplier', {
        id: params.id,
        postalCode: form.value.postalCode,
        houseNumber: form.value.houseNumber,
        houseNumberAddition: form.value.houseNumberAddition,
        street: form.value.street,
        city: form.value.city,
        country: form.value.country,
      });
    };

    const UpdateSupplier3 = () => {
      dispatch('patchSupplier', {
        id: params.id,
        primaryColor: form.value.primaryColor,
        secondaryColor: form.value.secondaryColor,
      });
    };


    const handleChange = (type, event) => {
      if (type === 'SENDER') {
        form.value.senderID = event;
        return;
      }
      if (type === 'TFA') {
        form.value.enforceTFA = event;
        return;
      }
    };

    const handleUpload = async (info) => {
      if (info.file.status === 'done') {
        await dispatch('getUser', params.id);
        await dispatch('fileUploadedSuccess');
      } else if (info.file.status === 'error') {
        await dispatch('fileUploadedError');
      }
    };

    const fileUploadHeaders = {
      Authorization: `Bearer ${getItem('access_token')}`
    };

    onMounted(() => {
      dispatch('getSupplier', params.id);
    });

    return {
      form,
      rules1,
      rules2,
      rules3,
      errorSupplier,
      loadingSupplier,
      UpdateSupplier1,
      UpdateSupplier2,
      UpdateSupplier3,
      moment,
      handleChange,
      tfaOptions,
      fileUploadHeaders,
      handleUpload,
      countryOptions,
    };
  },
});
</script>
